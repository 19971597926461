<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <product-listing-show :id="id" @gotAttrs="assignAttrs"></product-listing-show>
    <product-item-index
      :filtering="filtering"
      :ams_template="ams_template"
    ></product-item-index>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ProductListingShow from './ProductListingShow.vue'
import ProductItemIndex from './ProductItemIndex.vue'

export default {
  components: {
    VuexBreadcrumb,
    ProductListingShow,
    ProductItemIndex
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        product_listing_ids: this.id
      },
      ams_template: {
        type: 'product-items',
        description: undefined,
        attributes: {
          'product-listing-id': this.id,
          'collection-id': undefined,
          'purchased-price-cents': 0,
          'estimated-min-price-cents': 0,
          'estimated-max-price-cents': 0,
          'authenticated-min-price-cents': 0,
          'authenticated-max-price-cents': 0,
          'asking-price-cents': 0,
          'sold-price-cents': 0,
          'estimated-quality': 9000,
          'authenticated-quality': 0
        }
      },
      attrs: {},
    }
  },
  methods: {
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.ams_template.description = description + '# ' + attrs['edition']
    }
  }
}
</script>